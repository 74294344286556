import React from "react";
function ContactSection() {
  return (
    <div
      id="contact"
      className="container-fluid"
      style={{ padding: "6rem 0", background: "#242424" }}
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-delay="60"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
    >
      <explorug-contact
        themeColor="#00d092"
        backgroundColor="#242424"
        textColor="white"
        product="Explorug"
        fontFamily="'Open Sans', 'sans-serifs'"
        email="info@explorug.net"
      ></explorug-contact>
    </div>
  );
}

export default ContactSection;
